.App {
}

ul{
  list-style-type: none;
}

li{
  padding-top:12px;
}
